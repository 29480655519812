'use client';
import {
	ESTIMATEDTIMEICON,
	icon_congress,
	ORDERIDICON,
	ORDERPLACEDICON,
	SMSICON,
	TOTALORDERAMOUNTICON
} from '@/assets/icons';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styles from './AcknowledgeCongratulations.module.scss';

const AcknowledgeCongratulations = ({
	title = 'Congratulations!',
	message = 'Order placed successfully',
	isCollapsible = true,
	step = 1,
	showStep = true,
	data
}) => {
	const [iscollapsed, setIscollapsed] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleLoading = () => {
		setLoading(true); // Show loader
		setTimeout(() => {
			setLoading(false); // Hide loader after transition
		}, 300); // Adjust the delay for the loader duration (e.g., 300ms)
	};

	useEffect(() => {
		handleLoading();
	}, []);

	const renderOrderDetails = () => (
		<div>
			{[
				{
					icon: TOTALORDERAMOUNTICON,
					title: 'Total Order Amount',
					value: `৳ ${data?.po_payable_total}`
				},
				{
					icon: ORDERIDICON,
					title: 'Order ID',
					value: `# ${data?.po_id}`
				},
				{
					icon: ESTIMATEDTIMEICON,
					title: 'Estimated Time',
					value: data?.delivery_time
				},
				{
					icon: SMSICON,
					title: 'Confirmation will be sent to your Arogga App, SMS',
					value: 'Check your Arogga App or Phone SMS'
				}
			].map((item, index) => (
				<div className={styles.parent} key={index}>
					<Image src={item.icon} width={20} height={20} alt={item.title} />
					<div>
						<p className={styles.title}>{item.title}</p>
						<p className={styles.subTitle} style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}>
							{item.value}
						</p>
					</div>
				</div>
			))}
		</div>
	);

	const renderTimeline = () => (
		<div style={{ marginTop: '20px' }}>
			<div className={styles.timeline}>
				<div>
					<Image src={ORDERPLACEDICON} width={20} height={20} alt='Order Placed Icon' />
				</div>
				<div className={step === 1 ? styles.line : styles.line_placed}></div>
				<div>
					{step === 2 ? (
						<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
							<path
								d='M0.625 10C0.625 4.82233 4.82233 0.625 10 0.625C15.1777 0.625 19.375 4.82233 19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625 10Z'
								fill='#0BA259'
								stroke='#0BA259'
								strokeWidth='1.25'
							/>
							<path
								d='M14.2473 6.65838L8.28066 12.4167L6.69733 10.7251C6.40566 10.4501 5.94733 10.4334 5.614 10.6667C5.289 10.9084 5.19733 11.3334 5.39733 11.6751L7.27233 14.7251C7.45566 15.0084 7.77233 15.1834 8.13066 15.1834C8.47233 15.1834 8.79733 15.0084 8.98066 14.7251C9.28066 14.3334 15.0057 7.50838 15.0057 7.50838C15.7557 6.74172 14.8473 6.06672 14.2473 6.65005V6.65838Z'
								fill='white'
							/>
						</svg>
					) : (
						<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
							<circle cx='10' cy='10' r='3.33333' fill='#CBD5E0' />
						</svg>
					)}
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: '40px' }}>
				<p className={styles.green_text}>Order placed</p>
				<p style={{ color: step === 2 ? '#0BA259' : '#718096', fontWeight: '400' }}>
					{step === 1 ? 'Pay Online' : 'Paid'}
				</p>
			</div>
		</div>
	);

	useEffect(() => {
		!isCollapsible && setIscollapsed(false);
	}, [isCollapsible]);

	if (!isCollapsible) {
		return (
			<div className={styles.confirmModal}>
				<div className={`${styles.icon}`}>
					<Image src={icon_congress} width={56} height={56} alt='congress' />
				</div>
				<div className={styles.content}>
					<div className={styles.title}>{title}</div>
					{message && <div className={styles.message}>{message}</div>}
				</div>
				{showStep && renderTimeline()}
				<div>{renderOrderDetails()}</div>
			</div>
		);
	}
	return (
		<>
			<div className={styles.confirmModal}>
				<div className={`${styles.icon}`}>
					<Image src={icon_congress} width={56} height={56} alt='congress' />
				</div>
				<div className={styles.content}>
					<div className={styles.title}>{title}</div>
					{message && <div className={styles.message}>{message}</div>}
				</div>
				{showStep && renderTimeline()}
			</div>
			<div>
				{!iscollapsed ? (
					<>
						<div>
							{renderOrderDetails()}
							{isCollapsible && (
								<p
									className={styles.text_style}
									style={{
										color: '#0E7673',
										fontSize: '14px',
										fontWeight: '500',
										marginTop: '20px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										gap: '5px',
										cursor: 'pointer'
									}}
									onClick={() => setIscollapsed(!iscollapsed)}>
									View less{' '}
									<MdKeyboardArrowUp
										style={{ color: '#0E7673', fontSize: '18px', fontWeight: '400', cursor: 'pointer' }}
									/>
								</p>
							)}
						</div>
					</>
				) : (
					isCollapsible && (
						<p
							className={styles.text_style}
							style={{
								color: '#0E7673',
								fontSize: '14px',
								fontWeight: '500',
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '5px',
								cursor: 'pointer'
							}}
							onClick={() => setIscollapsed(!iscollapsed)}>
							View details{' '}
							<MdKeyboardArrowDown
								style={{ color: '#0E7673', fontSize: '18px', fontWeight: '400', cursor: 'pointer' }}
							/>
						</p>
					)
				)}
			</div>
		</>
	);
};

export default AcknowledgeCongratulations;
